/*aa
自作のスクリプトはここに
 */

//ローディング
const loading_top = () => {
    $(window).delay(700).queue((next) => {
        $('.loading_top').addClass('is-hide');
        setTimeout(() => {
            $('.loading_top').fadeOut(300);
        }, 400);
        next();
    });

    if (navigator.userAgent.match(/(Trident)/i)) {
        // Internet Explorer 11 の場合は「edgeで開く」を表示する。
        $('.l-header__text-alert').css({display: 'block'});
    }
}

const loading_low = () => {
    $(window).on('load', () => {
        $(".loading_low").delay(300).fadeOut(300);
    });
    const loaderClose = () => {
        $(".loading_low").fadeOut(300);
    }
    setTimeout(loaderClose, 10000);
}

//サイド固定ナビ
const fixNav = () => {
    const fixBtn = $('.u-fix_nav');
    const wHeight = window.innerHeight;
    fixBtn.hide();
    //スクロールがファーストビュー過ぎたらボタン表示
    $(window).on('scroll', event => {
        let $this = $(event.currentTarget);
        if ($this.scrollTop() > wHeight) {
            fixBtn.fadeIn();
        } else {
            fixBtn.fadeOut();
        }
    });
}

//タブ
const tab = () => {

    $('.js-tab_btn').find('li').on('click', event => {
        // if (window.matchMedia( "(min-width: 768px)" ).matches) {
        // }
        let $this = $(event.currentTarget);
        let index = $('.js-tab_btn').find('li').index($this);
        $('.js-tab_btn').find('li').removeClass('is-act');
        $('.js-tab_body').removeClass('is-act').hide();
        $this.addClass('is-act');
        $('.js-tab_body').eq(index).fadeIn('fast');
        $('.js-tab_body').eq(index).addClass('is-act');
        scrollComparison();
        threeDots();
        // viewTheDetails();

        // $(window).on('load resize', event =>{
        // 	let $this = $(event.currentTarget);
        // 	/** window load resize trigger functions  **/
        // 	const winW = $this.innerWidth();
        // 		if( winsW >= 767) {//PC
        // 			$('.c-cat_middlelist02').find('.c-cat_middlebox02__overview').tile(3);
        // 		}
        // 		else if ( winW <= 767 ) {//SP
        // 			$('.c-cat_middlelist02').find('.c-cat_middlebox02__overview').tile(2);
        // 		}
        // });

        // 比較表表示用ボタン追加(RENTAL-3822)
        if (index == 0) {
            $('#js-tab01subbtn').hide();
            $('#js-tab02subbtn').show();
        } else if (index == 1) {
            $('#js-tab02subbtn').hide();
            $('#js-tab01subbtn').show();
        }

    });
}


//Gナビドロップダウン
const gnaviDrop = () => {
    $('.gnavi').find('.parent').each((index, element) => {
        let $this = $(element);
        $this.on({
            'mouseenter': event => {
                let $this = $(event.currentTarget);
                $this.find('.mega_menu').addClass('is-act');
                $this.addClass('is-act');

                let menu_h = $('.mega_menu__search_inner.first_inner').outerHeight(true);
                $('.mega_menu-search .container').css('height', menu_h);

            }, //end mouseenter func
            'mouseleave': event => {
                    let $this = $(event.currentTarget);
                    $this.find('.mega_menu').removeClass('is-act');
                    $this.removeClass('is-act');
                } //end mouseleave func
        });
    });
}


// const gnaviDrop = ()=>{
// 	$('.gnavi').find('.parent').each((index,element)=> {
//     let $this = $(element);
//     $this.on({
//       'mouseenter':event=>{
//           let $this = $(event.currentTarget);
//           $this.find('.mega_menu').stop(true,true).delay(200).fadeIn(200);
//           $this.addClass('is-act');

//           let menu_h = $('.mega_menu__search_inner.first_inner').outerHeight(true);
//           $('.mega_menu-search .container').css('height',menu_h );

//       },//end mouseenter func
//       'mouseleave':event=>{
//           let $this = $(event.currentTarget);
//           $this.find('.mega_menu').stop(true,true).delay(100).fadeOut(100);
//           $this.removeClass('is-act');
//       }//end mouseleave func
//     });
//   });
// }

//Gナビドロップダウン（タブ）
const gnaviDropTab = () => {
    $('.mega_menu__search_list.large').each((index, element) => {
        let $this = $(element);
        $this.find('li').on({
            'mouseenter': event => {
                let $this = $(event.currentTarget);
                const index = $this.index(this);
                $('.mega_menu__search_list').find('li').removeClass('is-act');
                $this.addClass('is-act');
                $this.parents('.mega_menu__search').next().find('.mega_menu__search_inner').stop(true, true).fadeOut(100);
                $this.parents('.mega_menu__search').next().find('.mega_menu__search_inner').eq(index).stop(true, true).fadeIn(100);

            }, //end mouseenter func
            'mouseleave': event => {
                let $this = $(event.currentTarget);
                //$('.mega_menu__search_list').find('li').removeClass('is-act');
            }
        });




        //クリックイベントの場合
        // $this.parents('.mega_menu__search').prev().find('.mega_menu__search_list li').on('click', event => {
        //   $this.find('li').removeClass('is-act');
        // });
        // $this.find('li').on('click', event => {
        //   let $this = $(event.currentTarget);
        //   const index = $this.index(this);
        //   $this.parent().find('li').removeClass('is-act');
        //   $this.addClass('is-act');
        //   $this.parents('.mega_menu__search').next().find('.mega_menu__search_inner').hide();
        //   $this.parents('.mega_menu__search').next().find('.mega_menu__search_inner').eq(index).fadeIn('fast');
        // });
    });
}

//Gナビドロップダウン（アコーディオン ）
const gnaviDropAcc = () => {
    $('.mega_menu__search_list.middle').find('li').each((index, element) => {
        let $this = $(element);
        $this.on({
            'mouseenter': event => {
                let $this = $(event.currentTarget);
                $this.find('.mega_menu__search_child').stop(true, true).delay(200).fadeIn(200);

                $this.find('.mega_menu__search_child .mega_menu__search_list.small li').each((index, element) => {
                    let $this = $(element);

                    let thisText = $this.find('.txt').text();
                    let textLength = thisText.length;
                    if (textLength > 29) {
                        let showText = thisText.substring(0, 29);
                        let insertText = showText += '…';
                        $this.find('.txt').html(insertText);
                    };
                });


            }, //end mouseenter func
            'mouseleave': event => {
                    let $this = $(event.currentTarget);
                    $this.find('.mega_menu__search_child').stop(true, true).delay(100).fadeOut(100);
                } //end mouseleave func
        });
    });
}

//カテゴリー（アコーディオン）
const catListAcc = () => {
    $('.c-cat_box').each((index, element) => {
        let $this = $(element);
        let childHeight = $this.find('.c-cat_child_box').outerHeight(true);
        $this.find('span').on('click', event => {
            let $this = $(event.currentTarget).parent();
            let thisPosition = $this.offset().top;
            $this.toggleClass('is-act');

            if ($this.hasClass('is-act')) {
                $this.css('padding-bottom', childHeight);
                $('body,html').animate({ scrollTop: thisPosition }, '');
            } else {
                $this.css('padding-bottom', '');
            };

        });
        $this.siblings().find('span').on('click', event => {
            $this.css('padding-bottom', '').removeClass('is-act');
        });

        let thisText = $this.find('.c-cat_child_box .c-cat_child_box__ttl .text').text();
        let textLength = thisText.length;
        if (textLength > 83) {
            let showText = thisText.substring(0, 83);
            let insertText = showText += '…';
            $this.find('.c-cat_child_box .c-cat_child_box__ttl .text').html(insertText);
        };


        $('.c-cat_child_box__close').on('click', event => {
            $('.c-cat_box').css('padding-bottom', '').removeClass('is-act');
        });

    });


    /*   $('.c-cat_box').on('click', event => {
        let $this = $(event.currentTarget);
        let index = $('.c-cat_box').index($this);
        let children = $('.c-cat_child_box').eq(index);
        let children_h = $('.c-cat_child_box').eq(index).height();

        $this.toggleClass('is-act');
        children.slideToggle(100);
        children.toggleClass('is-act');

        if($this.siblings('.c-cat_box').hasClass('is-act')){//他のc-cat_boxが開いていたら自身を閉じる
          $('.c-cat_box').removeClass('is-act');
          $('.c-cat_child_box').removeClass('is-act');
          $('.c-cat_child_box').hide();
          $('.c-cat_box').css('padding-bottom', '');
          $('.c-cat_child_box').css('top', '');
          $this.addClass('is-act');
          children.slideDown(100);
          children.addClass('is-act');
          $this.css('padding-bottom', children_h);
          children.css('top', $this.position().top + $this.height());
        }

        if(children.hasClass('is-act')){//自身のc-cat_child_boxが開いていたら
          $this.css('padding-bottom', children_h);
          children.css('top', $this.position().top + $this.height());
        } else {
          $this.css('padding-bottom', '');
          children.animate({ top: ''}, 100 );
          //$this.animate({ 'padding-bottom': ''}, 110 );
          //children.css('top', '');
        }

        // if (window.matchMedia( "(max-width: 768px)" ).matches) {
        //   if( $('.p-index_category__btn-open').hasClass('is-act')){
        //     let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
        //     let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
        //     $('.c-cat_list').css('height', box_h - ttl_h);
        //   }
        // }

      });

      $('.c-cat_child_box__close').on('click', event => {
        $('.c-cat_child_box').slideUp(100);
        $('.c-cat_box').removeClass('is-act');
        $('.c-cat_child_box').removeClass('is-act');
        //$('.c-cat_box').css('padding-bottom', '');
        //$('.c-cat_child_box').animate({ top: ''}, 100 );
        $('.c-cat_box').animate({ 'padding-bottom': ''}, 110 );
        $('.c-cat_child_box').css('top', '');
      });
      */
}


// const cat_list_acc = ()=>{
//   $('.c-cat_box').on('click', event => {
//     // if (window.matchMedia( "(min-width: 768px)" ).matches) {
//     // }
//     let $this = $(event.currentTarget);
//     let index = $('.c-cat_box').index($this);
//     let children = $('.c-cat_child_box').eq(index);
//     let children_h = $('.c-cat_child_box').eq(index).height();

//     $('.c-cat_box').css('padding-bottom', '');
//     $('.c-cat_child_box').css('top', '');
//     $('.c-cat_child_box').hide();
//     $this.css('padding-bottom', children_h);
//     children.css('top', $this.position().top + $this.height());
//     children.slideDown(100);

//     $('.c-cat_box').removeClass('is-act');
//     $this.addClass('is-act');

//     if (window.matchMedia( "(max-width: 768px)" ).matches) {
//       if( $('.p-index_category__btn-open').hasClass('is-act')){
//         let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
//         let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
//         $('.c-cat_list').css('height', box_h - ttl_h);
//       }
//     }

//   });

//   $('.c-cat_child_box__close').on('click', event => {
//     $('.c-cat_box').removeClass('is-act');
//     $('.c-cat_box').css('padding-bottom', '');
//     $('.c-cat_child_box').css('top', '');
//     $('.c-cat_child_box').hide();
//   });
// }


//カテゴリー（sp用アコーディオン）

const catSpAcc = () => {

    let $this = $(event.currentTarget);

    // モバイルのTOP画面でカテゴリの9番目以降を片付ける。(8番目までだけ表示する)
    $('.c-cat_list').each((index, element) => {
        let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
        let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
        let cat_h = box_h - ttl_h;
        $('.c-cat_list').css('height', cat_h);
    });

    $('.c-cat_box').on('click', event => {
        if ($('.p-index_category__btn-open').hasClass('is-act')) {
            let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
            let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
            let cat_h = box_h - ttl_h;
            $('.c-cat_list').css('height', cat_h);
        }
    });

    $('.p-index_category__btn-open').on('click', event => {
        let $this = $(event.currentTarget);

        //リストの高さを取得
        let box = [];
        for (let i = 0; i < $('.c-cat_box:nth-child(even)').length; i++) {
            box.push($('.c-cat_box:nth-child(even)').eq(i).height());
        }
        let result = 0;
        for (const value of box) {
            result += value;
        }
        let list_h = result;

        $('.c-cat_list').stop().animate({ height: list_h }, 300, function() {
            $('.c-cat_list').css('height', '');
        });
        $this.toggleClass("is-act");
        $('.p-index_category__btn-close').addClass("is-act");
    });

    $('.p-index_category__btn-close').on('click', event => {
        let $this = $(event.currentTarget);
        let position = $('.p-index_category').offset().top;
        let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
        let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
        let cat_h = box_h - ttl_h;
        $('.c-cat_list').css('height', cat_h);
        $this.toggleClass("is-act");
        $('.p-index_category__btn-open').addClass("is-act");
        $('.c-cat_box').css('padding-bottom', '').removeClass('is-act');
        $('body,html').animate({ scrollTop: position }, '');
    });
}

// const cat_sp_acc = ()=>{
//   let ttl_h = $('.p-index_category .c-ttl01').outerHeight();
//   let box_h = $('.c-cat_list .c-cat_box:nth-child(9)').position().top;
//   let list_h = box_h - ttl_h;
//   $('.c-cat_list').css('height', box_h - ttl_h);

//   $('.p-index_category__btn-open').on('click', event => {
//     let $this = $(event.currentTarget);
//     $('.c-cat_list').css('height', '');
//     $this.toggleClass("is-act");
//     $('.p-index_category__btn-close').addClass("is-act");
//   });

//   $('.p-index_category__btn-close').on('click', event => {
//     let $this = $(event.currentTarget);
//     let position = $('.p-index_category').offset().top;
//     $('.c-cat_list').css('height', box_h - ttl_h);
//     $this.toggleClass("is-act");
//     $('.p-index_category__btn-open').addClass("is-act");
//     $('.c-cat_box').removeClass('is-act');
//     $('.c-cat_box').css('padding-bottom', '');
//     $('.c-cat_child_box').css('top', '');
//     $('.c-cat_child_box').hide();
// 		$('body,html').animate({scrollTop:position}, '');
//   });
// }

//メニューアコーディオン
const menuAcc = () => {
    $('.p-index_menu__btn,.c-sidebox_category_spbtn').on('click', event => {
        let $this = $(event.currentTarget);
        $this.next('.p-index_menu__list,.c-sidebox_category').stop(true, true).slideToggle();
        $this.toggleClass('is-act');
        var self =  $this.next('.p-index_menu__list,.c-sidebox_category');
        $('.p-index_menu__list,.c-sidebox_category').not(self).slideUp();
        $('.p-index_menu__btn,.c-sidebox_category_spbtn').not($this).removeClass('is-act');
    });

}

//アコーディオン 開閉のみのclass追加
const actAcc = () => {
    $('.accbtn').on('click', event => {
        let $this = $(event.currentTarget);
        $this.next('.acccontent').stop(true, true).slideToggle();
        $this.toggleClass('is-act');
        $this.next('.acccontent').toggleClass('is-act');
    });
}

//SPナビ
const drawer = () => {
    $('.hamburger').on('click', event => {
        let wH = $(window).height();
        $('.drawer').addClass('is-act');
        $('#page').prepend('<div class="drawer_overlay"></div>').hide().fadeIn(300);
    });
    $('.drawer').find('.close').on('click', event => {
        $('.drawer').removeClass('is-act');
        $('.drawer_overlay').remove();
    });
    $('#page').on('click', '.drawer_overlay', event => {
        $('.drawer').removeClass('is-act');
        $('.drawer_overlay').remove();
    });
}

//SPナビサブメニュー
const drawerDropdown = () => {
    $('.drawer__menu_parent').each((index, element) => {
        let $this = $(element);
        $this.on('click', event => {
            let $this = $(event.currentTarget);
            //$this.siblings().removeClass('is-act');
            $this.toggleClass('is-act');
            //$this.find('.drawer__menu_child').slideToggle();
            $this.next('.drawer__menu_child').slideToggle();
            var self = $this.find('.drawer__menu_child');
            //$('.drawer__menu_child').not(self).removeClass('is-act').slideUp();
            //$('.drawer__menu_child').not(self).slideUp();
            $('.drawer__menu_parent').not($this).removeClass('is-act');
            $('.drawer__menu_parent').not($this).next('.drawer__menu_child').slideUp();
        });
    });
}

//メインスライダー
const mainSlider = () => {
    if ($('.p-kv__slider').length) {
        $('.p-kv__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            //pauseOnHover: true,
            autoplay: true,
            //infinite: true,
            arrows: false,
            dots: true,
        });
    }
}

//最近チェックした計測器スライダー（TOP）
const itemSlider02 = () => {
    if ($('.p-index__slider .c-item_verList,.p-cart__slider .c-item_verList').length) {
        $('.p-index__slider .c-item_verList,.p-cart__slider .c-item_verList').slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            //pauseOnHover: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            dots: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }]
        });
    }
}

//最近見た計測器の関連機器スライダー（TOP）
const itemSlider01 = () => {
    if ($('.p-index__slider .c-item_horList').length) {
        $('.p-index__slider .c-item_horList').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            //pauseOnHover: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            dots: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToScroll: 3
                }
            }]
        });
    }
}

//下層スライダー
const itemSlider03 = () => {
    if ($('.c-low_slider .c-item_verList').length) {
        $('.c-low_slider .c-item_verList').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            //pauseOnHover: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            dots: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToScroll: 3
                }
            }]
        });
    }
}

//バナースライダー
const itemSlider04 = () => {
    if ($('.p-index__slider .c-item_bnrList').length) {
        $('.p-index__slider .c-item_bnrList').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            //pauseOnHover: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            dots: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                        //dots: true,
                        //arrows: false
                }
            }]
        });
        var slideCount = $('.c-item_bnrList li').length;
        if (slideCount < 3) {
            arrows: false
        }
    }
}

//製品スライダー
const itemSlider05 = () => {
    if ($('.p-category-product__slider_large').length) {
        $('.p-category-product__slider_large').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            //fade: true,
            asNavFor: '.p-category-product__slider_thumb' //サムネイルのクラス名
        });
        $('.p-category-product__slider_thumb').slick({
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            //arrows: true,
            asNavFor: '.p-category-product__slider_large', //スライダー本体のクラス名
            centerMode: true,
            centerPadding: 0,
            focusOnSelect: true,
        });
        $('.p-category-product__slider_large').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            if ($('.p-category-product__slider_thumb li').length < 6) {
                $('.p-category-product__slider_thumb').slick('slickSetOption', 'centerMode', true, true);
            }
        });
    }
}

//最近チェックした計測器スライダー（カテゴリ）
const itemSlider06 = () => {
    if ($('.p-index__slider .c-item_verList').length) {
        $('.p-index__slider .c-item_verList').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            //pauseOnHover: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            dots: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }]
        });
    }
}

//サイドアコーディオン
const sideAcc = () => {
    $('.c-sidebox_category h3.btn').each((index, element) => {
        let $this = $(element);
        if ($this.hasClass('is-close')) {
            $this.next('.c-sidebox_categorylist--acc').hide();
        }
        $this.on('click', event => {
            let $this = $(event.currentTarget);
            //$this.siblings().removeClass('is-act');
            $this.toggleClass('is-close');
            $this.next('.c-sidebox_categorylist--acc').slideToggle();
        });

        if (window.matchMedia("(max-width: 768px)").matches) {
            $('.c-sidebox_category h3.btn').addClass('is-close');
            $('.c-sidebox_categorylist--acc').hide();
        }

    });
}

//サイドアコーディオン02
const sideAcc02 = () => {
    $('.c-acc_btn').each((index, element) => {
        let $this = $(element);
        if ($this.hasClass('is-open')) {
            $this.next('.c-acc_list').show();
        }
        $this.on('click', event => {
            let $this = $(event.currentTarget);
            //$this.siblings().removeClass('is-act');
            $this.toggleClass('is-open');
            $this.next('.c-acc_list').slideToggle();
        });

        if (window.matchMedia("(max-width: 768px)").matches) {
            $('.c-acc_btn').removeClass('is-open');
            $('.c-acc_list').hide();
        }

    });
}

//サイド子カテゴリー
const sideAcc_child = () => {
    $('.c-sidebox__otherlist >li').each((index, element) => {
        let $this = $(element);

        if (window.matchMedia("(max-width: 768px)").matches) { //SP

            $this.find('> a').replaceWith(function() {
                $this.find('> a').replaceWith("<span>" + $(this).text() + "</span>")
            });

            $this.on('click', event => {
                let $this = $(event.currentTarget);
                //$this.siblings().removeClass('is-act');
                $this.toggleClass('is-open');
                $this.find('.c-sidebox__cat_child').slideToggle();
            });

        } else { //PC

            $this.on({
                'mouseenter': event => {
                    let $this = $(event.currentTarget);
                    $this.find('.c-sidebox__cat_child').stop(true, true).delay(100).fadeIn(100);
                    $this.addClass('is-act');
                }, //end mouseenter func
                'mouseleave': event => {
                        let $this = $(event.currentTarget);
                        $this.find('.c-sidebox__cat_child').stop(true, true).delay(100).fadeOut(100);
                        $this.removeClass('is-act');
                    } //end mouseleave func
            });

        }


    });
}

//よくある質問アコーディオン
const faqAcc02 = () => {
    if ($('.p-category-product__faq_box').closest('.c-print').length > 0) {
        $('.p-category-product__faq_box').addClass('is-open');
        $('.p-category-product__faq_box').find('dd').show();

        $('.p-category-product__faq_box dt').on('click', event => {
            let $this = $(event.currentTarget);
            $this.next('dd').stop(true, true).slideToggle();
            $this.parent().toggleClass('is-open');
        });
    } else {
        // $('.p-category-product__faq_box:eq(0)').addClass('is-open'); // 商品詳細 よくある質問 ２件目以降を閉じる設定。
        // $('.p-category-product__faq_box:eq(0)').find('dd').show(); // 商品詳細 よくある質問 ２件目以降を閉じる設定。
        $('.p-category-product__faq_box').addClass('is-open');
        $('.p-category-product__faq_box').find('dd').show();

        $('.p-category-product__faq_box dt').on('click', event => {
            let $this = $(event.currentTarget);
            $this.next('dd').stop(true, true).slideToggle();
            $this.parent().toggleClass('is-open');
        });
    }
}

//製品詳細ページ追従ヘッダー
const fixedProduct = () => {

    let w_height = $(window).outerHeight();
    let start_pos = 0;

    $(window).on('scroll', () => {
        let $this = $(event.currentTarget);
        let menuHeight = $('.p-product_fix').outerHeight();
        let current_pos = $this.scrollTop();

        //アンカーのポジション取得
        let anchor_pos = [];
        for (let i = 0; i < $('.p-category-product [id^=sec]').length; i++) {
            let sec_pos = $('.p-category-product [id^=sec]').eq(i).offset().top;
            let sec_pos_first = $('.p-category-product [id^=sec]').eq(0).offset().top;
            let sec_pos_last = $('.p-category-product [id^=sec]').eq(-1).offset().top;
            let sec_pos_last_bottom = sec_pos_last + $('.p-category-product [id^=sec]').eq(-1).outerHeight();

            anchor_pos.push(Math.floor(sec_pos));

            if (current_pos < sec_pos_first || current_pos > sec_pos_last_bottom) { //アンカー以外の範囲
                $('.p-category-product__anchor ul li a').removeClass('is-current');
            } else if (current_pos >= sec_pos) { //各アンカーの範囲
                $('.p-category-product__anchor ul li a').removeClass('is-current');
                $('.p-category-product__anchor ul li a').eq(i).addClass('is-current');
            }

        }


        if (current_pos > (start_pos + 0.01)) { // 下にスクロールした場合
            $('.p-product_fix').addClass('is-act').css({ 'top': 0 + 'px' });
            if ($(window).scrollTop() >= w_height) {
                $(".p-product_fix").removeClass('is-act').css({ 'top': '-' + menuHeight + 'px' });
            }
        } else if (current_pos < start_pos) { // 上にスクロールした場合

            if ($.inArray(current_pos, anchor_pos) !== -1) { //アンカーポジションにクリックでスクロールされたら
                $('.p-product_fix').removeClass('is-act').css({ 'top': '' });
            } else {
                $('.p-product_fix').addClass('is-act').css({ 'top': 0 + 'px' });
            }

        }
        start_pos = current_pos;

        if (current_pos == 0) {
            $(".p-product_fix").removeClass('is-act').css({ 'top': '' });
        }
    });

}

//モバイル追従ヘッダー
const fixedmobile_globalsearch = () => {

    // let w_height = $(window).outerHeight();
    // let start_pos = 0;
    if (window.matchMedia("(max-width: 768px)").matches) {

        $(window).on('scroll', () => {
            let $this = $(event.currentTarget);
            let menuHeight = $('.l-header__top').outerHeight();
            let current_pos = $this.scrollTop();

            if (current_pos > (menuHeight + 0.01)) { // global seachから下にスクロールした場合に表示
                $('#xs-gnavfixed').addClass('is-act').css({ 'top': 0 + 'px' });
            } else {
                    $('#xs-gnavfixed').removeClass('is-act').css({ 'top': '' });
            }
        });

    }

}


//製品詳細アコーディオン
const proAcc = () => {
    if ($('.p-category-product__faq_box').closest('.c-print').length > 0) {
        $('.p-category-product__acc_btn').on('click', event => {
            let $this = $(event.currentTarget);
            $this.toggleClass('is-close');
            $this.parent().toggleClass('is-open');
            if ($this.hasClass('is-close')) {
                $this.text('続きを見る');
            } else {
                $this.text('閉じる');
            }
        });
    } else {
        $('.p-category-product__acc_btn').on('click', event => {
            let $this = $(event.currentTarget);
            $this.toggleClass('is-close');
            $this.parent().toggleClass('is-open');
            if ($this.hasClass('is-close')) {
                $this.text('閉じる');
            } else {
                $this.text('続きを見る');
            }
        });
    }

}

//このページは参考になりましたか？切り替え
const faqSwitch = () => {
    $('.p-faq_info__btnlist li').on('click', event => {
        let $this = $(event.currentTarget);
        if ($this.hasClass('no')) {
            $('.no_area').fadeIn(200);
        } else {
            $('.no_area').fadeOut(100);
        }
    });
}


//文字列を省略
const threeDots = () => {

    $('.js-txt_hidden').each(function() {
        var $target = $(this);
        var html = $target.html();
        var $clone = $target.clone(); //対象要素のクローンを作成する
        $clone //クローンにスタイルを適応
            .css({
                display: 'none',
                position: 'absolute',
                overflow: 'visible'
            })
            .width($target.width())
            .height('auto');
        $target.after($clone); //対象要素の最後にクローンを追加する。
        //クローンの高さが対象要素より高い場合は、文字列を一文字消す。
        var targetHeight = $target.height();
        while ((html.length > 0) && ($clone.height() > targetHeight)) {
            html = html.substr(0, html.length - 1);
            $clone.html(html + '...');
        }
        // 省略前の文字列が描画されたあとで文字列を省略する処理が走ると見た目が良くない。省略前の文字列を初期状態で不可視にしておき文字列を省略した後に表示する。
        $target.html($clone.html()).css({visibility: 'visible'}); //対象要素にクローンの要素を格納する。
        $clone.remove(); //クローンを削除する。
    });

}

//文字列を省略(リサイズ)
const threeDots_resize = () => {

    $(document).ready(function() {
        $('.js-txt_hidden').each(function() {

            var $target = $(this);
            var rest = "";

            // オリジナルの文章を取得
            var html = $target.html();

            // 対象の要素を、高さにautoを指定し非表示で複製する
            var $clone = $target.clone();
            $clone
                .css({
                    display: 'none',
                })
                .width($target.width())
                .height('auto');

            // 目印クラスをつけて
            // DOMを追加
            $clone.addClass("js-txt_hidden-rest");
            $target.after($clone);

            // 指定した高さになるまで、1文字ずつ消去していく
            var targetHeight = $target.height();
            while ((html.length > 0) && ($clone.height() > targetHeight)) {
                rest = html.substr(html.length - 1, 1) + rest;
                html = html.substr(0, html.length - 1);
                $clone.html(html + "..."); // 高さ更新
            }

            // 文章差し替え
            if (rest == "") {
                $target.html(html);
            } else {
                $target.html(html + '...');
            }
            // 省略前の文字列が描画されたあとで文字列を省略する処理が走ると見た目が良くない。省略前の文字列を初期状態で不可視にしておき文字列を省略した後に表示する。
            $target.css({visibility: 'visible'});
            // リサイズ用に次の要素に残りの文章を入れておく
            $clone.html(rest);
        });
    });

    var timer = false;
    $(window).resize(function() {
        // タイマーによって、リサイズ単位毎に関数が実行され、重くなるのを防ぐ
        if (timer !== false) {
            clearTimeout(timer);
        }
        timer = setTimeout(function() {
            $('.js-txt_hidden').each(function() {

                var $target = $(this);
                var rest;
                // 以前にリサイズしたか(document.readyで必ず<p class="js-txt_hidden-rest">
                // は存在するのでこの条件文はtrueを返すが、念のため)
                if ($target.next().hasClass("js-txt_hidden-rest")) {
                    // 省略していた文章を取得
                    rest = $target.next().html();
                    // 省略していた文章が空ではなかったとき、本文には３点リーダーが表示されて
                    // いるので、その３文字を削除
                    if (rest != "") {
                        $target.html($target.html().slice(0, -3)); // 末尾の...を削除
                    }
                    // これがないと永遠に<p class="js-txt_hidden-rest">が増えていく
                    $target.next().remove();
                } else {
                    rest = "";
                }

                // オリジナルの文章を復元
                var html = $target.html() + rest;

                // 対象の要素を、高さにautoを指定し非表示で複製する
                // 方針としては、このクローン(オリジナルの文章を保持)を非表示でブラウザに配置させ、
                // クローンの文字消去と元のボックスとの高さ比較を行うことによって、
                // クローンが元のボックスと同様の高さになったときの文章で差し替える
                var $clone = $target.clone();
                $clone.html(html);
                $clone
                    .css({
                        display: 'none',
                    })
                    .width($target.width())
                    .height('auto');

                // 目印クラスをつけて
                // DOMを追加 (これにより高さを獲得)
                $clone.addClass("js-txt_hidden-rest");
                $target.after($clone);

                rest = "";
                // 指定した高さになるまで、1文字ずつ消去していくと同時に、
                // 文章が完全消去されないように rest に退避させておく
                var targetHeight = $target.height();
                while ((html.length > 0) && ($clone.height() > targetHeight)) {
                    rest = html.substr(html.length - 1, 1) + rest;
                    html = html.substr(0, html.length - 1);
                    $clone.html(html + "..."); // 高さ更新
                }

                // 文章差し替え
                // rest が空っぽということは、三点リーダーを表示する必要がないということ
                if (rest == "") {
                    $target.html(html);
                } else {
                    $target.html(html + '...');
                }
                // 省略前の文字列が描画されたあとで文字列を省略する処理が走ると見た目が良くない。省略前の文字列を初期状態で不可視にしておき文字列を省略した後に表示する。
                $target.css({visibility: 'visible'});
                // 次のリサイズ用に次の要素に残りの文章を入れておく
                $clone.html(rest);
            });
        }, 200);
    });

    $('.js-txt_hidden').each((index, element) => {
        let $this = $(element);
        let $target = $this;
        let html = $target.html();
        let $clone = $target.clone(); //対象要素のクローンを作成する
        $clone //クローンにスタイルを適応
            .css({
                display: 'none',
                position: 'absolute',
                overflow: 'visible'
            })
            .width($target.width())
            .height('auto');
        $target.after($clone); //対象要素の最後にクローンを追加する。
        //クローンの高さが対象要素より高い場合は、文字列を一文字消す。
        var targetHeight = $target.height();
        while ((html.length > 0) && ($clone.height() > targetHeight)) {
            html = html.substr(0, html.length - 1);
            $clone.html(html + '...');
        }
        // 省略前の文字列が描画されたあとで文字列を省略する処理が走ると見た目が良くない。省略前の文字列を初期状態で不可視にしておき文字列を省略した後に表示する。
        $target.html($clone.html()).css({visibility: 'visible'}); //対象要素にクローンの要素を格納する。
        //$clone.remove();//クローンを削除する。
    });
}

////文字列を省略して「 > 詳細をみる」
//const viewTheDetails = () => {
//
//    $('.js-txt_hidden_view-the-details').each(function() {
//        var $target = $(this);
//        var html = $target.html();
//        var $clone = $target.clone(); //対象要素のクローンを作成する
//        $clone //クローンにスタイルを適応
//            .css({
//                display: 'none',
//                position: 'absolute',
//                overflow: 'visible'
//            })
//            .width($target.width())
//            .height('auto');
//        $target.after($clone); //対象要素の最後にクローンを追加する。
//        //クローンの高さが対象要素より高い場合は、文字列を一文字消す。
//        while ((html.length > 0) && ($clone.height() > $target.height())) {
//            html = html.substr(0, html.length - 1);
//            // $clone.html(html + '...');
//            $clone.html(html + ' > 詳細をみる');
//        }
//        // $target.html($clone.html()); //対象要素にクローンの要素を格納する。
//        $target.html($clone.html().slice(0, -8)); //対象要素にクローンの要素を格納する。末尾の「 > 詳細をみる」を削除
//        $clone.remove(); //クローンを削除する。
//    });
//
//}

//文字列を省略して「 > 詳細をみる」(リサイズ)
const viewTheDetails_resize = () => {

    $(document).ready(function() {
        $('.js-txt_hidden_view-the-details').each(function() {

            var $target = $(this);
            var rest = "";

            // オリジナルの文章を取得
            var html = $target.html();

            // 対象の要素を、高さにautoを指定し非表示で複製する
            var $clone = $target.clone();
            $clone
                .css({
                    display: 'none',
                })
                .width($target.width())
                .height('auto');

            // 目印クラスをつけて
            // DOMを追加
            $clone.addClass("js-txt_hidden-rest");
            $target.after($clone);

            // 指定した高さになるまで、1文字ずつ消去していく
            while ((html.length > 0) && ($clone.height() > $target.height())) {
                rest = html.substr(html.length - 1, 1) + rest;
                html = html.substr(0, html.length - 1);
                // $clone.html(html + "..."); // 高さ更新
                // $clone.html(html + " > 詳細をみる"); // 高さ更新
                $clone.html(html + "..." + " > 詳細をみる"); // 高さ更新
            }

            // 文章差し替え
            if (rest == "") {
                $target.html(html);
                // $target.parent().find('.view-the-details').hide(); // 「 > 詳細をみる」を消す。
            } else {
                $target.html(html + '...');
                // $target.html(html + ' > 詳細をみる');
                // $target.html(html);
            }
            // リサイズ用に次の要素に残りの文章を入れておく
            $clone.html(rest);
        });
    });

//    var timer = false;
//    $(window).resize(function() {
//        // タイマーによって、リサイズ単位毎に関数が実行され、重くなるのを防ぐ
//        if (timer !== false) {
//            clearTimeout(timer);
//        }
//        timer = setTimeout(function() {
//            $('.js-txt_hidden_view-the-details').each(function() {
//
//                var $target = $(this);
//                var rest;
//                // 以前にリサイズしたか(document.readyで必ず<p class="js-txt_hidden-rest">
//                // は存在するのでこの条件文はtrueを返すが、念のため)
//                if ($target.next().hasClass("js-txt_hidden-rest")) {
//                    // 省略していた文章を取得
//                    rest = $target.next().html();
//                    // 省略していた文章が空ではなかったとき、本文には「 > 詳細をみる」が表示されて
//                    // いるので、その８文字を削除
//                    if (rest != "") {
//                        // $target.html($target.html().slice(0, -3)); // 末尾の...を削除
//                        $target.html($target.html().slice(0, -8)); // 末尾の「 > 詳細をみる」を削除
//                    }
//                    // これがないと永遠に<p class="js-txt_hidden-rest">が増えていく
//                    $target.next().remove();
//                } else {
//                    rest = "";
//                }
//
//                // オリジナルの文章を復元
//                var html = $target.html() + rest;
//
//                // 対象の要素を、高さにautoを指定し非表示で複製する
//                // 方針としては、このクローン(オリジナルの文章を保持)を非表示でブラウザに配置させ、
//                // クローンの文字消去と元のボックスとの高さ比較を行うことによって、
//                // クローンが元のボックスと同様の高さになったときの文章で差し替える
//                var $clone = $target.clone();
//                $clone.html(html);
//                $clone
//                    .css({
//                        display: 'none',
//                    })
//                    .width($target.width())
//                    .height('auto');
//
//                // 目印クラスをつけて
//                // DOMを追加 (これにより高さを獲得)
//                $clone.addClass("js-txt_hidden-rest");
//                $target.after($clone);
//
//                rest = "";
//                // 指定した高さになるまで、1文字ずつ消去していくと同時に、
//                // 文章が完全消去されないように rest に退避させておく
//                while ((html.length > 0) && ($clone.height() > $target.height())) {
//                    rest = html.substr(html.length - 1, 1) + rest;
//                    html = html.substr(0, html.length - 1);
//                    // $clone.html(html + "..."); // 高さ更新
//                    $clone.html(html + " > 詳細をみる"); // 高さ更新
//                }
//
//                // 文章差し替え
//                // rest が空っぽということは、「 > 詳細をみる」を表示する必要がないということ
//                if (rest == "") {
//                    $target.html(html);
//                } else {
//                    // $target.html(html + '...');
//                    // $target.html(html + ' > 詳細をみる');
//                    $target.html(html);
//                }
//                // 次のリサイズ用に次の要素に残りの文章を入れておく
//                $clone.html(rest);
//            });
//        }, 200);
//    });

//    $('.js-txt_hidden_view-the-details').each((index, element) => {
//        let $this = $(element);
//        let $target = $this;
//        // let html = $target.html();
//        let html = $target.html().slice(0, $target.html().length);
//        let $clone = $target.clone(); //対象要素のクローンを作成する
//        $clone //クローンにスタイルを適応
//            .css({
//                display: 'none',
//                position: 'absolute',
//                overflow: 'visible'
//            })
//            .width($target.width())
//            .height('auto');
//        $target.after($clone); //対象要素の最後にクローンを追加する。
//        //クローンの高さが対象要素より高い場合は、文字列を一文字消す。
//        while ((html.length > 0) && ($clone.height() > $target.height())) {
//            html = html.substr(0, html.length - 1);
//            // $clone.html(html + '...');
//            $clone.html(html + ' > 詳細をみる');
//        }
//        // $target.html($clone.html()); //対象要素にクローンの要素を格納する。
//        // $target.html(html); //対象要素にクローンの要素を格納する。
//        // $target.html($clone.html().slice(0, -8)); //対象要素にクローンの要素を格納する。末尾の「 > 詳細をみる」を削除
//        //$clone.remove();//クローンを削除する。
//    });
}

//リクエスト注文
const modaalRequest = () => {
    if ($('.request_popup').length) {
        $('.request_popup').modaal({ type: 'inline', background_scroll: false, custom_class: 'p-kv_popup__wrapper' });
    }
}

//Youtubeポップアップ
const modaalYoutube = () => {
    if ($('.p-category-product__slider_large .youtube_frame').length) {
        $('.p-category-product__slider_large .youtube_frame').modaal({ type: 'video', custom_class: 'p-category-product__youtube_wrapper' });
    }
}


//headerプレスホルダー
const hPlace = () => {

    $(window).on('load resize', () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            $(".l-header__search .searchform__text").attr('placeholder', '商品名・型番・サポート情報など');
        } else {
            $(".l-header__search .searchform__text").attr('placeholder', '商品名・型番・サポート情報など');
        }
    });

}

//比較表スクロールバー
const scrollComparison = () => {
    let scrollbox_w = $(".p-category__comparison_table").width();
    $("#scrollbar .inner").width(scrollbox_w);
    $("#scrollbar, #scrollbox").on('scroll', () => {
        let $this = $(event.currentTarget);
        if ($this.attr('id') === 'scrollbar') {
            $('#scrollbox').scrollLeft($this.scrollLeft());
        } else {
            $("#scrollbar").scrollLeft($this.scrollLeft());
        }
    });
}

//比較表チェックボックス
const checkboxComparison = () => {

    $('.p-category__comparison_box').each((index, element) => {
        let $this = $(element);
        $this.find('.check_on').on('click', event => {
            //$this.toggleClass('is-check');
            if ($this.find('input[type="checkbox"]').prop('checked')) {
                $this.find('input[type="checkbox"]').removeAttr('checked').prop('checked', false).change();
                $this.removeClass('is-check');
            } else {
                $this.find('input[type="checkbox"]').attr('checked', true).prop('checked', true).change();
                $this.addClass('is-check');
            }

        });
    });


    // $('.p-category__comparison_checkbox input[type="checkbox"]').each((index,element)=> {
    //   let $this = $(element);
    //   $this.on('click', event => {
    //     let $this = $(event.currentTarget);
    //     $this.parents('.p-category__comparison_box').toggleClass('is-check');
    //   });
    // });
}

//全機器選択ボタン
const selectChange = () => {

    $('.p-category__btnlink').find('.li01 span').each((index, element) => {
        let $this = $(element);
        $this.on('click', event => {
            $this.toggleClass('is-act');
            //$this.text("全機器選択");
            if ($this.hasClass('is-act')) {
                $this.html('<svg class="icon"><use xlink:href="#fa-check" /></svg>全機器選択');
            } else {
                $this.html('<svg class="icon"><use xlink:href="#fa-check" /></svg>全機器選択解除');
            }
        });
    });
}

//headerサジェスト機能
/* const headerSuggest = () => {
    let suggest = [
        "工業用ビデオスコープ IPLEX NX φ4.0mm",
        "工業用ビデオスコープ IPLEX NX φ6.0mm",
        "工業用ビデオスコープ IPLEX NX φ6.0mm",
        "先端可動式デジタル内視鏡3m VJ-ADV3 φ6.9mm",
        "工業用ビデオスコープ IPLEX RX φ6.0mm",
        "工業用ビデオスコープ IPLEX YS φ8.5mm",
        "工業用ビデオスコープ IPLEX LX φ6.0mm",
        "工業用内視鏡 XL Go+(φ6.1-8.0m)",
        "工業用ビデオスコープ IPLEX TX φ2.4mm",
        "先端可動式デジタル内視鏡 5m VJ-ADV5 φ6.9mm",
        "先端可動式ビデオ内視鏡3m VJ-3/STR",
        "工業用ビデオスコープ IPLEX FX φ6.0mm 3.5m",
        "工業用ビデオスコープ IPLEX FX φ6.0mm 7.5m",
        "工業用ビデオスコープ IPLEX FX φ6.0mm 12.0m",
        "工業用ビデオスコープ IPLEX FX φ6.0mm 18.0m",
        "工業用ビデオスコープ IPLEX FX φ4.0mm 3.5m",
        "工業用ビデオスコープ IPLEX MX R"
    ];

    $(".l-header__search .searchform__text").autocomplete({
        source: suggest,
        select: function(event, ui) {
            $(".l-header__search .searchform__text").val(ui.item.label);
            $(".header_searchform").submit();
        }
    });

    $(".c-side_search .searchform__text").autocomplete({
        source: suggest,
        select: function(event, ui) {
            $(".c-side_search .searchform__text").val(ui.item.label);
            $(".side_searchform").submit();
        }
    });

    let url = location.href;

    if (url.indexOf("?s=") > -1) {
        let param = location.search;
        let param_r = param.replace("?s=", "");
        $(".l-header__search .searchform__text").val(decodeURIComponent(param_r));
    }


} */

//よくある質問　検索結果の文字制限
const searchResultTxt = () => {
    $('.p-faq_search_result__box').each((index, element) => {
        let $this = $(element);
        let thisText = $this.find('.txt').text();
        let textLength = thisText.length;

        if (window.matchMedia("(max-width: 768px)").matches) {
            if (textLength > 85) {
                let showText = thisText.substring(0, 85);
                let insertText = showText += '…';
                $this.find('.txt').html(insertText);
            };
        } else {
            if (textLength > 249) {
                let showText = thisText.substring(0, 249);
                let insertText = showText += '…';
                $this.find('.txt').html(insertText);
            }
        }

    });
}

//絞り込み検索　checkboxの文字制限
/* const checkboxTxt = () => {
    $('.c-sidebox_form__label').find('li').each((index, element) => {
        let $this = $(element);
        let thisText = $this.find('span').text();
        let textLength = thisText.length;

        if (window.matchMedia("(max-width: 768px)").matches) {
            if (textLength > 18) {
                let showText = thisText.substring(0, 18);
                let insertText = showText += '…';
                $this.find('span').html(insertText);
            }
        } else {
            if ($this.width() > 200) {
    			$this.find('span').addClass('more');
    		}
          }

      });
    }
*/

//カテゴリー一覧の説明文の文字制限（超えるとmoreボタン）
const catlistTxt = () => {
    $('.c-category_listbox__large').each((index, element) => {
        let $this = $(element);
        let thisText = $this.find('.text p').text();
        let textLength = thisText.length;

        if ($this.hasClass('c-category_listbox__large')) {
            var count = 100;
        }


        if (textLength > count) {
            let showText = thisText.substring(0, count);
            let insertText = showText += '…';
            $this.find('.text p').html(insertText);
            $this.find('.text .more').addClass('is_show');
        }

        // if (window.matchMedia( "(max-width: 768px)" ).matches) { //SP
        //       if (textLength > 83) {
        //         let showText = thisText.substring(0, 83);
        //         let insertText = showText += '…';
        //         $this.find('.text p').html(insertText);
        //         $this.find('.text .more').addClass('is_show');
        //       }
        //   } else { //PC
        //   if (textLength > 110) {
        //     let showText = thisText.substring(0, 110);
        //     let insertText = showText += '…';
        //     $this.find('.text p').html(insertText);
        //     $this.find('.text .more').addClass('is_show');
        //   }
        // }

    });
}

//ランキング一覧の説明文のスマホのみ文字制限（超えるとmoreボタン）
const rankinglistTxt = () => {

    $('.p-category__ranking .c-faq_catbox02').not('.ranking01').each((index, element) => {
        let $this = $(element);
        $this.find('.more').hide();
        $(document).ready(function() {
            //$(window).on('load resize',()=>{
            //$(window).resize(function () {
            //let $this = $(event.currentTarget);
            /** window load resize trigger functions  **/
            //const winW = $this.innerWidth();
            //if ( $(window).innerWidth() <= 767 ) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                if ($this.find('.text').height() > 50) {
                    $this.find('.text').removeClass('is-normal');
                    $this.find('.more').show();
                    $this.find('.more').on('click', event => {
                        let $this = $(event.currentTarget);
                        $this.toggleClass('is-close');
                        $this.parents('.text').toggleClass('is-open');
                        if ($this.hasClass('is-close')) {
                            $this.find('span').text('閉じる');
                        } else {
                            $this.find('span').text('続きを見る');
                        }

                    });
                }
            }
        });
    });




    // $('.p-category__ranking .c-faq_catbox02').not('.ranking01').each((index,element)=> {
    //   let $this = $(element);
    //   let thisText = $this.find('.text').text();
    //   let textLength = thisText.length;

    //   if($this.hasClass('c-faq_catbox02')){
    //     var count = 43;
    //   }

    //   $(window).on('load resize',()=>{
    //     if (window.matchMedia( "(max-width: 768px)" ).matches) {
    //       if (textLength > count) {
    //         let showText = thisText.substring(0, count);
    //         let insertText = showText += '…';
    //         $this.find('.text').html(insertText);
    //         $this.find('.more').addClass('is_show');
    //       }
    //     }
    //   });

    // });
}

//数字増減
const spinner = () => {
    $('.c-count').each((index, element) => {
        let $this = $(element);
        let step = 1;
        if ($this.find(".c-count__input").attr("min")) {
            var min = $this.find(".c-count__input").attr("min");
            var min = parseInt(min);
        } else {
            var min = 0;
        }

        //var max = 100;
        if ($this.find(".c-count__input").attr("max")) {
            // htmlから最大値の設定(max="999"等)を取得する。
            var max = $this.find(".c-count__input").attr("max");
            var max = parseInt(max);
        } else {
            var max = 999999; // DBの最大桁は6桁。
        }

        var kazu = $this.find(".c-count__input").val();
        var kazu = parseInt(kazu);

        $this.find(".spinner_down").on('click', event => {
            var kazu = $this.find(".c-count__input").val();
            var kazu = parseInt(kazu);
            kazu -= step;
            $this.find(".c-count__input").val(kazu);
            if (kazu < min) { $this.find(".c-count__input").val(min); }
            $this.find(".c-count__input").change(); // 逐次更新処理のためにイベントを発生させる。

            // RENTAL-529 見積カート用のmax制御。逐次更新フラグの初期化を設定する。
            var $thisTarget = $(event.currentTarget);
            var detailIndex = $thisTarget.attr('field');
            var optionIndex = $thisTarget.attr('field');
            var optiontTag = $thisTarget.attr('field');
            if (typeof detailIndex != "undefined" && detailIndex.length > 1) {
                detailIndex = detailIndex.split('_')[0];
                optionIndex = optionIndex.split('_')[1];
                optiontTag = optiontTag.split('_')[2];
            }
            $thisTarget.parents("tr").find("input[name='cartDetailDtoList[" + detailIndex + "]." + optiontTag + "OptionDtoList[" + optionIndex + "].chikujiKoshinMukoFlg']").val("false");
        });

        $this.find(".spinner_up").on('click', event => {
            var kazu = $this.find(".c-count__input").val();
            var kazu = parseInt(kazu);
            kazu += step;
            $this.find(".c-count__input").val(kazu);
            if (max < kazu) { $this.find(".c-count__input").val(max); } // 最大値で制限する。
            // RENTAL-529 見積カート用のmax制御。オプション台数×レンタル上限をmaxに設定する。
            var $thisTarget = $(event.currentTarget);
            var detailIndex = $thisTarget.attr('field');
            var optionIndex = $thisTarget.attr('field');
            var optiontTag = $thisTarget.attr('field');
            if (typeof detailIndex != "undefined" && detailIndex.length > 1) {
                detailIndex = detailIndex.split('_')[0];
                optionIndex = optionIndex.split('_')[1];
                optiontTag = optiontTag.split('_')[2];
            }
            var mukoFlg = false;
            var rental_daisu = $("input[name='cartDetailDtoList[" + detailIndex + "].rentalDaisu']").val();
            var jogen = $thisTarget.parents("tr").find("input[name$='.daisuSentakuJogen']").val();
            var max = rental_daisu * jogen;
            var option_daisu = $("input[name^='cartDetailDtoList[" + detailIndex + "]." + optiontTag + "OptionDtoList[" + optionIndex + "].optionDaisu']").val();
            if (option_daisu > max) {
                $thisTarget.parents("tr").find("input[name='cartDetailDtoList[" + detailIndex + "]." + optiontTag + "OptionDtoList[" + optionIndex + "].chikujiKoshinMukoFlg']").val("true");
                mukoFlg = true;
            } else {
                $thisTarget.parents("tr").find("input[name='cartDetailDtoList[" + detailIndex + "]." + optiontTag + "OptionDtoList[" + optionIndex + "].chikujiKoshinMukoFlg']").val("false");
                mukoFlg = false;
            }
            if (!mukoFlg) { // オプション台数がmax値を超えた場合は逐次更新するとエラーメッセージが表示されるためイベントを発生させない。
                $this.find(".c-count__input").change(); // 逐次更新処理のためにイベントを発生させる。
            }
        });
        //if ( kazu < min ) { kazu = min; }

    });
}

//アラート用モーダル
const alertModal = () => {

        //オーバーレイを出現させる
        $( "body" ).append( '<div class="c-cmp-modal__overlay"></div>' ) ;
        $( ".c-cmp-modal__overlay" ).fadeIn( "slow" ) ;

        //コンテンツをフェードインする
        $(".c-cmp-modal__overlay").before('<div class="c-cmp-modal__content"><p><svg class="icon"><use xlink:href="#fa-exclamation-triangle" /></svg>開始日付を入力してください。</p><div class="c-cmp-modal__close">閉じる</div></div>');
        $( ".c-cmp-modal__content" ).fadeIn( "slow" ) ;

        //コンテンツをセンタリングする
        centeringModalSyncer() ;

        //[.c-cmp-modal__overlay]、または[.c-cmp-modal__close]をクリックしたら…
        $( ".c-cmp-modal__overlay,.c-cmp-modal__close" ).unbind().click( function(){

            //[.c-cmp-modal__content]と[.c-cmp-modal__overlay]をフェードアウトした後に…
            $( ".c-cmp-modal__content,.c-cmp-modal__overlay" ).fadeOut( "slow" , function(){

                //[.c-cmp-modal__overlay]を削除する
                $('.c-cmp-modal__overlay').remove() ;

            } ) ;

        } ) ;


//リサイズされたら、センタリングをする関数[centeringModalSyncer()]を実行する
$( window ).resize( centeringModalSyncer ) ;

    //センタリングを実行する関数
    function centeringModalSyncer() {

        //画面(ウィンドウ)の幅、高さを取得
        var w = $( window ).width() ;
        var h = $( window ).height() ;

        // コンテンツ(.c-cmp-modal__overlay)の幅、高さを取得
        var cw = $( ".c-cmp-modal__content" ).outerWidth();
        var ch = $( ".c-cmp-modal__content" ).outerHeight();

        //センタリングを実行する
        $( ".c-cmp-modal__content" ).css( {"left": ((w - cw)/2) + "px","top": ((h - ch)/2) + "px"} ) ;

    }
}


//カレンダーピッカー
const datePicker = () => {
    // $.datepickerオブジェクトが存在するかどうか確認
    if ($.datepicker) {
        // 日本語化
        $.datepicker.regional['ja'] = {
            closeText: '閉じる',
            prevText: '<svg class="icon"><use xlink:href="#fa-chevron-circle-left" /></svg>',
            nextText: '<svg class="icon"><use xlink:href="#fa-chevron-circle-right" /></svg>',
            currentText: '今日',
            monthNames: ['1月', '2月', '3月', '4月', '5月', '6月',
                '7月', '8月', '9月', '10月', '11月', '12月'
            ],
            monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月',
                '7月', '8月', '9月', '10月', '11月', '12月'
            ],
            dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
            dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
            dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
            weekHeader: '週',
            dateFormat: 'yy/mm/dd',
            firstDay: 0,
            isRTL: false,
            showMonthAfterYear: true,
            yearSuffix: '年'
        };
        $.datepicker.setDefaults($.datepicker.regional['ja']);
    }

    $('.p-cart__period_date').each((index, element) => {
        let $this = $(element);
        const INPUT_DATE_PATTERN = /^\d{4}\/\d{1,2}\/\d{1,2}$/;

        //開始日付入力
        $this.find(".c-datepicker_input.data_start").datepicker({
            minDate: "dateToday",
            showOn: "button",
            buttonText: "<svg class='icon'><use xlink:href='#fa-calendar-alt' /></svg>",

        });

        //終了日付入力
        $this.find(".c-datepicker_input.data_end").datepicker({
            minDate: "dateToday",
            showOn: "button",
            buttonText: "<svg class='icon'><use xlink:href='#fa-calendar-alt' /></svg>",
            beforeShow: function(input, inst) {
                // //終了日付の最小日付を開始日付+設定日数に設定
                // let period = $this.parents("tr").siblings(".period").find(".c-count__input").val();
                // 終了日付の最小日付を開始日付+最低レンタル期間の5日間に設定
                let period = 5;
                let start_date = $this.find($(".c-datepicker_input.data_start")).datepicker('getDate');

                if ($this.find($(".c-datepicker_input.data_start")).val()) {
                    start_date.setDate(start_date.getDate() + parseInt(period - 1));
                    $this.find($(".c-datepicker_input.data_end")).datepicker("option", "minDate", start_date);
                } else {
                    //alert('開始日付が入力されていません。');
                    alertModal();
                    return false;
                }
            }
        });

        $this.find(".c-datepicker_input.data_end").on('click', event => {
            if ($this.find(".c-datepicker_input.data_start").val().length == 0) {
                //alert('開始日付が入力されていません。');
                alertModal();
                $this.find(".c-datepicker_input.data_end").blur();
            }
        });

        // 開始日付に日付を入れると終了日付が連動して変わる
        $this.find(".c-datepicker_input.data_start").on('change', event => {
          if ($this.find(".c-datepicker_input.data_start").val().length !== 0) {
            let $this = $(event.currentTarget);
            var date_text_kaishi_date = new Date($this.val());
            // 開始日付が有効な日付の場合だけ終了日付を更新する様にする。
            if (!isNaN(date_text_kaishi_date) && $this.val().match(INPUT_DATE_PATTERN)) {
              let end_date = $this.datepicker('getDate');
              let period = $this.parents("tr").siblings(".period").find(".c-count__input").val();

              if (!period || period < 5) {
                period = 5; // レンタル日数が取得できない、または5日間未満の場合は5日間に変更する。
                $this.parents("tr").siblings(".period").find(".c-count__input").val(period).change(); // 変更した値の逐次更新処理のためにイベントを発生させる。
              }
              end_date.setDate(end_date.getDate() + parseInt(period - 1));
              let formatted = $.datepicker.formatDate("yy/mm/dd", end_date);
              $this.parent(".c-datepicker").siblings(".c-datepicker").find('.c-datepicker_input.data_end').val(formatted);
            }
          }
        });



        // /*ここ！！！！！！！！！！！*/

        // //レンタル期間が開始終了共に入ってる場合にレンタル日数を変更すると、終了日付が連動して変わる
        // $this.parents("tr").siblings(".period").find(".c-count__button input").on('click', event => {
        //     let $this = $(event.currentTarget);
        //     // let period = $this.parent().siblings(".c-count__input").val();
        //     let period;
        //     if ($this.hasClass("spinner_down")) {
        //         console.log('111');
        //         period = parseInt($this.parent().siblings(".c-count__input").val()) - 1;
        //     } else if ($this.hasClass("spinner_up")) {
        //         console.log('222');
        //         period = parseInt($this.parent().siblings(".c-count__input").val()) + 1;
        //     }


        //     let date = $this.parents(".period").siblings("tr").find(".c-datepicker_input");

        //     let data_start_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").val();
        //     let data_end_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").val();

        //     if (data_start_val.length !== 0 && data_end_val.length !== 0) {
        //         let end_date = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").datepicker('getDate');

        //         console.log(period);

        //         end_date.setDate(end_date.getDate() + parseInt(period));
        //         let formatted = $.datepicker.formatDate("yy/mm/dd", end_date);
        //         $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_end").val(formatted);
        //     }
        // });

        // 開始日付が有効な日付の場合、レンタル日数を手入力で変更すると、終了日付が連動して変わる
        $this.parents("tr").siblings(".period").find(".c-count__input").on('change', event => {
            let $this = $(event.currentTarget);
            let period = $this.val();
            let min = $this.attr("min");
            let max = $this.attr("max");
            let data_start_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").val();
            // let data_end_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_end").val();

            // if (data_start_val.length !== 0 && data_end_val.length !== 0) {
            var date_text_kaishi_date = new Date(data_start_val);
            // 開始日付が有効な日付の場合だけレンタル日数を終了日付へ反映する様にする。
            if (!isNaN(date_text_kaishi_date) && data_start_val.match(INPUT_DATE_PATTERN)) {
                if (parseInt(min) > parseInt(period)) {
                    return;
                }
                if (parseInt(period) > parseInt(max)) {
                    return;
                }
                let start_date = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").datepicker('getDate');

                start_date.setDate(start_date.getDate() + parseInt(period - 1));
                let formatted = $.datepicker.formatDate("yy/mm/dd", start_date);
                $this.parents(".period").siblings("tr").find('.c-datepicker_input.data_end').val(formatted);
            }

        });

        // 開始日付が有効な日付の場合、レンタル日数をスピンアップ／ダウンで変更すると、終了日付が連動して変わる
        $this.parents("tr").siblings(".period").find(".c-count__button input").on('click', event => {
            let $this = $(event.currentTarget);
            let period;
            if ($this.hasClass("spinner_down")) {
                period = parseInt($this.parent().siblings(".c-count__input").val()) - 1;
            } else if ($this.hasClass("spinner_up")) {
                period = parseInt($this.parent().siblings(".c-count__input").val()) + 1;
            }
            let min = $this.parent().siblings(".c-count__input").attr("min");
            let data_start_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").val();
            // let data_end_val = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_end").val();

            // if (data_start_val.length !== 0 && data_end_val.length !== 0) {
            var date_text_kaishi_date = new Date(data_start_val);
            // 開始日付が有効な日付の場合だけレンタル日数を終了日付へ反映する様にする。
            if (!isNaN(date_text_kaishi_date) && data_start_val.match(INPUT_DATE_PATTERN)) {
                if ((min - 1) === period) {
                    return;
                }
                let start_date = $this.parents(".period").siblings("tr").find(".c-datepicker_input.data_start").datepicker('getDate');

                start_date.setDate(start_date.getDate() + parseInt(period - 1));
                let formatted = $.datepicker.formatDate("yy/mm/dd", start_date);
                $this.parents(".period").siblings("tr").find('.c-datepicker_input.data_end').val(formatted);
            }

        });

        // 終了日付に日付を入れるとレンタル日数が連動して変わる
        $this.find($(".c-datepicker_input.data_end")).on('change', event => {
          let $this = $(event.currentTarget);
          var data_start = $this.parent().siblings().find(".c-datepicker_input.data_start");

          var date_text_kaishi_date = new Date(data_start.val());
          var date_text_shuryo_date = new Date($this.val());
          // 開始日付と終了日付が有効な日付の場合だけレンタル日数を更新する様にする。
          if (!isNaN(date_text_kaishi_date) && data_start.val().match(INPUT_DATE_PATTERN) && !isNaN(date_text_shuryo_date) && $this.val().match(INPUT_DATE_PATTERN)) {
            let end_date = $this.datepicker('getDate');
            let formatted_end = $.datepicker.formatDate("yy/mm/dd", end_date);

            let start_date = $this.parent().siblings().find(".c-datepicker_input.data_start").datepicker('getDate');
            let formatted_start = $.datepicker.formatDate("yy/mm/dd", start_date);

            //比較する日付オブジェクトを２つ定義する
            var day1 = new Date(formatted_start);
            var day2 = new Date(formatted_end);

            //差日を求める（86,400,000ミリ秒＝１日）
            var termDay = (day2 - day1) / 86400000;

            $this.parents("tr").siblings(".period").find(".c-count__input").val(termDay + 1);
          }

        });


        //未定の場合はチェックにチェックすると入力不可
        $this.find('.is-toggleBtn_date').on('click', event => {
            let $this = $(event.currentTarget);
            $this.parents('.checkbox').siblings('.c-datepicker').find('.c-datepicker_input').val("");
            $this.parents('.checkbox').siblings('.c-datepicker').toggleClass('is-no_input');
        });




    });

}

//フォームでチェックボックスtoggle
const checkToggle = () => {

    //商品送付先住所チェック
    $('.is-toggle_address').hide();
    $('#is-toggleBtn_address').on('change', event => {
        let $this = $(event.currentTarget);
        $('.is-toggle_address').toggle();
    });

    //ご要望チェック
    $('.is-toggle_request').hide();
    $('#is-toggleBtn_request').on('change', event => {
        let $this = $(event.currentTarget);
        $('.is-toggle_request').toggle();
    });

    //企業・個人選択チェック
    $('.is-toggle_trigger').find('input').on('change', event => {
        let $this = $(event.currentTarget);
        if ($('.is-toggle_trigger label').eq(0).find('input').prop('checked')) {
            $('.is-toggle_company').show();

        } else {
            $('.is-toggle_company').hide();
        }
    });


    //使用予定チェック
    $('.is-kaishidate_trigger').find('input').on('change', event => {
        let $this = $(event.currentTarget);
        if ($('.is-kaishidate_trigger label').eq(0).find('input').prop('checked')) {
            $('.is-toggle_kaishidate').show();

        } else {
            $('.is-toggle_kaishidate').hide();
        }
    });


    //$('.c-contact_table').find('input[name="your_type"]').on('change', event => {
    //  let $this = $(event.currentTarget);
    //  if($('#is-toggleBtn_company').prop('checked')){
    //    $('.is-toggle_company').show();
    //  } else {
    //    $('.is-toggle_company').hide();
    //  }
    //});

    //校正証明書チェック
    $('.is-radio_issue').each((index, element) => {
        let $this = $(element);
        $this.find('input[type="radio"]').on('click', event => {
            let $this = $(event.currentTarget);
            $this.find('input[type="radio"]').prop('checked', true);
            $this.parent('label').siblings('label').find('input[type="radio"]').prop('checked', false);
        });

    });

}




/******/


/*
呼び出しはここで
 */
;

//グローバル変数でエラーを保持
window.lastError = null;

(($) => {

    $(() => {
    	try {
            loading_low();
            loading_top();
            fixNav();
            gnaviDrop();
            gnaviDropTab();
            gnaviDropAcc();
            tab();
            catListAcc();
            menuAcc();
            actAcc();
            drawer();
            drawerDropdown();
            mainSlider();
            itemSlider01();
            itemSlider02();
            itemSlider03();
            itemSlider04();
            itemSlider05();
            itemSlider06();
            sideAcc();
            sideAcc02();
            sideAcc_child();
            faqAcc02();
            proAcc();
            faqSwitch();
            threeDots_resize();
            viewTheDetails_resize();
            modaalRequest();
            modaalYoutube();
            hPlace();
            checkboxComparison();
            //headerSuggest();
            searchResultTxt();
            datePicker();
            spinner();
            //checkboxTxt();
            //catlistTxt();
            selectChange();
            checkToggle();
            rankinglistTxt();

            if ($('body').find('.p-product_fix')) {
                fixedProduct();
            }

            if ($('body').find('.l-header__bottom_fixed')) {
                fixedmobile_globalsearch();
            }

            //$(".drawer").load("../parts/drawer.html");
        } catch (error) {
            // エラーハンドリング処理
            logError(null, error);
            // エラーをグローバル変数に保持
            window.lastError = error;
            // F12ツールのコンソールに出力するために再度throwする
            throw error;
        }
    });

    $(window).on('load', () => {
        let $this = $(event.currentTarget);
        /** window load resize trigger functions  **/
        const winW = $this.innerWidth();
        const winH = $this.innerHeight();

        if (winW >= 767) { //PC

            //$('.c-cat_box').find('.ttl').tile(6);

        } else if (winW <= 767) { //SP

            if ($('body').find('.c-cat_box')) {
                catSpAcc();
            }


        }
    });

    $(window).on('scroll', () => {

    });

})(jQuery);

/******/

// エラーログ関数
const logError = (message, error, source = '', lineno = 0, colno = 0) => {
    const errorData = {
        message: error ? error.message : message,
        source: source,
        lineno: lineno,
        colno: colno,
        error: error ? error.toString() : 'Unknown Error', // errorがnullまたはundefinedなら'Unknown Error'とする
        // stackTrace: error ? error.stack : 'No Stack Trace' // スタックトレースを追加 // stackTraceをカット。(RENTAL-4430)
    };

    $.ajax({
        url: '/async/log-js-error',
        type: 'POST',
        data: JSON.stringify(errorData),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(data) {
            console.log('Error data sent to server:', data);
        }
    });
};

// 既存のエラーハンドラを保持
const originalErrorHandler = window.onerror;

window.onerror = function (message, source, lineno, colno, error) {
    // 既存の処理があれば実行
    if (originalErrorHandler) {
        originalErrorHandler(message, source, lineno, colno, error);
    }
    // すでにハンドリングされたエラーを無視
    if (window.lastError && message == 'Script error.') {
        window.lastError = null;
        return;
    }
    // こちらが新しい処理
    logError(message, error, source, lineno, colno);
};

//window.addEventListener('error', function(event) {
//    // エラーイベントオブジェクトからエラー情報を取得
//    const { message, filename, lineno, colno, error } = event;
//    // すでにハンドリングされたエラーを無視
//    if (window.lastError && message == 'Script error.') {
//        window.lastError = null;
//        return;
//    }
//    // こちらが新しい処理
//    logError(message, error, filename, lineno, colno);
//    // // エラーの伝播を防止
//    // event.preventDefault();
//}, true);
